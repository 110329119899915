<!-- 组件说明 -->
<template>
    <div class="manage" v-loading="loading">
        <div class="ls_flexalign_center search_part">
            <div class="opt_path ls_flex_1">
                <span @click="$router.push({ path: `/manage` })">学生管理</span>
                <span>标签管理</span>
            </div>
            <el-button size="medium" type="success" @click="addClass">
                新增标签
            </el-button>
        </div>
        <div class="work_list" v-if="classDataList.length > 0">
            <el-table :data="classDataList" highlight-current-row :header-cell-style="{ background: '#FAFAFA' }" style="width: 100%">
                <el-table-column label="标签名称">
                    <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="标签人数">
                    <template slot-scope="scope">
                        <span>{{ scope.row.stuCount }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="限制人数">
                    <template slot-scope="scope">
                        <span>{{ scope.row.maxNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间">
                    <template slot-scope="scope">
                        <span>
                            {{
                                scope.row.createTime | formatDate("yyyy-MM-dd")
                            }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" class-name="status-col" width="400px">
                    <template slot-scope="scope">
                        <div class="opt_part">
                            <span @click="
                            $router.push({
                                path: `/manage/classDetail?id=${scope.row.id}`,
                            })
                                ">
                                标签详细
                            </span>
                            <span @click="editClassName(scope.row)">
                                修改标签信息
                            </span>
                            <span @click="inviteStudent(scope.row)">
                                邀请学员加入
                            </span>
                            <span @click="delCalss(scope.row)">
                                删除
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-if="classDataList.length == 0 && !loading" class="no_data">
            <div class="empty">
                <i class="iconfont icon-queshengye_zanwushuju"></i>
            </div>
            <p class="tc">暂无数据</p>
        </div>
        <el-dialog :title="classTitle" :visible.sync="classShow" width="380px" top="10vh" :close-on-click-modal="false">
            <el-input placeholder="请输入标签名称" v-model.trim="className" />
            <div style="margin-top: 10px">
                <el-input placeholder="请输入限制人数" v-model.trim="maxNum" />
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="classShow = false">取消</el-button>
                <el-button type="primary" @click="createClass">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data () {
        return {
            classDataList: [],
            classTitle: "",
            classId: "",
            className: "",
            maxNum: "",
            classShow: false,
        };
    },
    //监控data中的数据变化
    watch: {},
    //监听属性 类似于data概念
    computed: {},
    methods: {
        //新增/修改标签
        createClass () {
            if (this.className == "") {
                this.$message({
                    message: "请输入标签名称",
                    type: "warning",
                });
                return;
            }
            if (this.maxNum == "") {
                this.$message({
                    message: "请输入标签人数限制",
                    type: "warning",
                });
                return;
            }
            if (this.classTitle == "编辑标签") {
                this.$ajax
                    .post("/user/group/edit", {
                        id: this.classId,
                        name: this.className,
                        maxNum: this.maxNum,
                    })
                    .then(res => {
                        this.classShow = false;
                        this.$message({
                            message: "标签名称修改成功",
                            type: "success",
                        });
                        this.getListClass();
                    });
            } else {
                this.$ajax
                    .post("/user/group/create", {
                        name: this.className,
                        maxNum: this.maxNum,
                    })
                    .then(res => {
                        this.classShow = false;
                        this.$message({
                            message: "创建标签成功",
                            type: "success",
                        });
                        this.getListClass();
                    });
            }
        },
        addClass () {
            this.classTitle = "新增标签";
            this.classShow = true;
            this.classId = "";
            this.className = "";
            this.maxNum = "";
        },
        delCalss (row) {
            this.$ajax.post("/user/group/del", { id: row.id }).then(res => {
                this.$message.success("标签删除成功")
                this.getListClass();
            })
        },
        editClassName (row) {
            this.classTitle = "编辑标签";
            this.classShow = true;
            this.classId = row.id;
            this.className = row.name;
            this.maxNum = row.maxNum;
        },
        getListClass () {
            this.loading = true;
            this.$ajax
                .post("/user/group/getList", {})
                .then(response => {
                    this.classDataList = response.data;
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                });
        },
        inviteStudent (row) {
            console.log(row.id);
            this.$ajax
                .post("/user/teacher/getStudentShareCode", {
                    groupId: row.id,
                })
                .then(res => {
                    this.$confirm(
                        `<div>
                            <div class="lh26 fz14">1. 引导学员扫码，加入对应标签即可；</div>
                            <img style="width: 160px; height: 160px; margin-top: 10px" src="https://api.qrserver.com/v1/create-qr-code/?data=${res.data}"></img>
                        </div>`,
                        "邀请学员加入",
                        {
                            showCancelButton: false,
                            showConfirmButton: false,
                            dangerouslyUseHTMLString: true,
                            center: true,
                        }
                    )
                        .then(() => { })
                        .catch(() => {
                            // on cancel
                        });
                });
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created () {
        this.loading = true;
        this.getListClass();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted () { },
};
</script>

<style lang="scss" scoped>
.search_part {
    >* {
        +* {
            margin-left: 7px;
        }

        &:nth-child(2) {
            width: 200px;
        }
    }
}

.manage {
    padding: 20px 30px 0 0;
    //   padding-top: 40px;
}

.work_list {
    margin-top: 20px;
    width: 100%;
    height: auto;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
}

.no_data {
    text-align: center;

    .empty {
        margin-top: 60px;
        margin-bottom: 24px;

        i {
            font-size: 130px;
            color: #999;
        }
    }

    p {
        font-size: 16px;
        color: #999999;
        margin-bottom: 139px;
    }
}

.page_part {
    height: 25px;
    margin-bottom: 10px;
    margin-top: 15px;
    padding: 10px 20px !important;
    text-align: center;
}
</style>
